.VideoDetails {
  width: 100%;
}

.VideoDetails .iframe-wrapper {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

.VideoDetails iframe {
  border: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.VideoDetails .title {
  border-bottom: solid 0.0625rem #eee;
  font-size: 1.125rem;
  font-weight: 400;
  padding-bottom: 1rem;
}

.VideoDetails .channel {
  color: inherit;
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: none;
}

.VideoDetails .published-date {
  color: #707070;
  font-size: 0.8125rem;
  margin: 0.25rem 0 1.5rem;
}

.VideoDetails .description {
  border-bottom: solid 0.0625rem #eee;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}

@media (min-width: 62em) {
  .VideoDetails {
    width: 40rem;
  }

  .VideoDetails iframe {
    height: 22.5rem;
    position: initial;
    width: 40rem;
  }

  .VideoDetails .iframe-wrapper {
    padding-bottom: 0;
  }
}
