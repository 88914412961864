.Header {
  background-color: #fff;
  box-shadow: 0 0.01rem 0.5rem #ddd;
  width: 100%;
}

.Header .visible-sm {
  display: inherit !important;
}

.Header .hidden-sm {
  display: none !important;
}

.Header .content {
  align-items: center;
  display: flex;
  height: 4rem;
  margin: 0 auto;
  max-width: 75rem;
  padding: 0 1rem;
}

.Header .brand {
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  margin: 0;
  margin-right: 5rem;
}

.Header .brand a {
  align-items: center;
  color: #282828;
  display: flex;
  text-decoration: none;
}

.Header .logo {
  color: #f00;
  font-size: 1.6rem;
  margin-right: 0.2rem;
}

.Header button {
  border: none;
  color: #828282;
  cursor: pointer;
  outline: none;
}

.Header button:focus,
.Header button:hover {
  color: #333;
}

.Header .icon-button {
  background-color: transparent;
  font-size: 1.6rem;
  padding: 0;
}

.Header .submit-button {
  background-color: #f8f8f8;
  border-left: 0.0625rem solid #ddd;
  border-radius: 0;
  width: 4.6875rem;
}

.Header .submit-button:focus,
.Header .submit-button:hover {
  background-color: #f0f0f0;
}

.Header .search-form {
  border: 0.0625rem solid #ddd;
  border-radius: 0.125rem;
  display: flex;
  width: 100%;
}

.Header .search-form input {
  border: none;
  color: inherit;
  font-family: inherit;
  font-weight: normal;
  padding: 0.4rem 0.7rem;
  width: 100%;
}

.Header .back-button {
  margin-right: 1rem;
}

.Header .search-button {
  margin-left: auto;
}

@media (min-width: 62em) {
  .Header .visible-md {
    display: inherit !important;
  }

  .Header .hidden-md {
    display: none !important;
  }

  .Header form,
  .Header input {
    max-width: 37.5rem;
    width: 37.5rem;
  }
}
