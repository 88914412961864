.Footer {
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
}

.Footer .text-right {
  text-align: right;
}

.Footer .text-muted {
  color: #6c757e;
}

.Footer .small {
  font-weight: 400;
}

.Footer .link {
  background-color: transparent;
  background-color: initial;
  color: #007bff;
  text-decoration: none;
}

.Footer .link:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (min-width: 62em) {
  .Footer {
    max-width: 75rem;
    padding: 1rem 5rem;
  }
}

.Header {
  background-color: #fff;
  box-shadow: 0 0.01rem 0.5rem #ddd;
  width: 100%;
}

.Header .visible-sm {
  display: inherit !important;
}

.Header .hidden-sm {
  display: none !important;
}

.Header .content {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 4rem;
  margin: 0 auto;
  max-width: 75rem;
  padding: 0 1rem;
}

.Header .brand {
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  margin: 0;
  margin-right: 5rem;
}

.Header .brand a {
  -webkit-align-items: center;
          align-items: center;
  color: #282828;
  display: -webkit-flex;
  display: flex;
  text-decoration: none;
}

.Header .logo {
  color: #f00;
  font-size: 1.6rem;
  margin-right: 0.2rem;
}

.Header button {
  border: none;
  color: #828282;
  cursor: pointer;
  outline: none;
}

.Header button:focus,
.Header button:hover {
  color: #333;
}

.Header .icon-button {
  background-color: transparent;
  font-size: 1.6rem;
  padding: 0;
}

.Header .submit-button {
  background-color: #f8f8f8;
  border-left: 0.0625rem solid #ddd;
  border-radius: 0;
  width: 4.6875rem;
}

.Header .submit-button:focus,
.Header .submit-button:hover {
  background-color: #f0f0f0;
}

.Header .search-form {
  border: 0.0625rem solid #ddd;
  border-radius: 0.125rem;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.Header .search-form input {
  border: none;
  color: inherit;
  font-family: inherit;
  font-weight: normal;
  padding: 0.4rem 0.7rem;
  width: 100%;
}

.Header .back-button {
  margin-right: 1rem;
}

.Header .search-button {
  margin-left: auto;
}

@media (min-width: 62em) {
  .Header .visible-md {
    display: inherit !important;
  }

  .Header .hidden-md {
    display: none !important;
  }

  .Header form,
  .Header input {
    max-width: 37.5rem;
    width: 37.5rem;
  }
}

.VideoDetails {
  width: 100%;
}

.VideoDetails .iframe-wrapper {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

.VideoDetails iframe {
  border: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.VideoDetails .title {
  border-bottom: solid 0.0625rem #eee;
  font-size: 1.125rem;
  font-weight: 400;
  padding-bottom: 1rem;
}

.VideoDetails .channel {
  color: inherit;
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: none;
}

.VideoDetails .published-date {
  color: #707070;
  font-size: 0.8125rem;
  margin: 0.25rem 0 1.5rem;
}

.VideoDetails .description {
  border-bottom: solid 0.0625rem #eee;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}

@media (min-width: 62em) {
  .VideoDetails {
    width: 40rem;
  }

  .VideoDetails iframe {
    height: 22.5rem;
    position: static;
    position: initial;
    width: 40rem;
  }

  .VideoDetails .iframe-wrapper {
    padding-bottom: 0;
  }
}

.VideoListItem {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  margin-top: 1rem;
}

.VideoListItem:first-child {
  margin-top: 0;
}

.VideoListItem img {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-right: 0.5rem;
}

.VideoListItem .info {
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.VideoListItem .title {
  font-size: 0.875rem;
  margin: 0;
}

.VideoListItem .channel {
  color: #707070;
  font-size: 0.8125rem;
  margin: 0.5rem 0;
}

.VideoList {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (min-width: 62em) {
  .VideoList {
    margin-left: 1.5rem;
    width: 33rem;
  }
}

html {
  background-color: #fafafa;
  color: #111;
  font-family: sans-serif;
  height: 100%;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  overflow: hidden;
  word-wrap: break-word;
}

body {
  height: 100%;
  overflow: auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  padding: 1.5rem 1rem;
  width: 100%;
}

@media (min-width: 62em) {
  .container {
    -webkit-flex-direction: row;
            flex-direction: row;
    max-width: 75rem;
    padding: 1.5rem 5rem;
  }
}

